import React from 'react';
import { CatalogTableRow } from './types';
import { TableColumn } from '@backstage/core-components';

// The columnFactories symbol is not directly exported, but through the
// CatalogTable.columns field.
/** @public */
export const columnFactories = Object.freeze({
  createTitleColumn(options?: {
    hidden?: boolean;
  }): TableColumn<CatalogTableRow> {
    return {
      title: 'Title',
      field: 'entity.metadata.title',
      hidden: options?.hidden,
      searchable: true,
    };
  },
  createBuildInfoColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Build Info',
      field: 'entity.metadata',
      render: ({ entity }) => {
        let buildInfo;
        if (entity?.metadata?.annotations && entity?.metadata?.annotations?.["backstage.io/source-location"]) {
          if (entity?.metadata?.annotations?.["aura/branch"]) {
            if (entity?.metadata?.annotations?.["aura/build-number"]) {
              try {
                buildInfo = entity?.metadata?.annotations?.["aura/branch"] + ' (' + entity?.metadata?.annotations?.["aura/build-number"] + ')'
              }
              catch (e) {
              }
            }
            else if (entity?.metadata?.annotations?.["aura/commit"]) {
              try {
                buildInfo = entity?.metadata?.annotations?.["aura/branch"] + ' (' + entity?.metadata?.annotations?.["aura/commit"].substring(0, 8) + ')'
              }
              catch (e) {
              }
            }
          }
        }

        return (
          <>
            {buildInfo}
          </>
        );
      }
    };
  },
  createDeployTimeColumn(): TableColumn<CatalogTableRow> {
    return {
      title: 'Deploy Time',
      field: 'entity.metadata',
      render: ({ entity }) => {
        let deployTime;
        if (entity?.metadata?.annotations?.["aura/deploy-date"]) {
          try {
            let deployTimeDateObject = new Date(entity?.metadata?.annotations?.["aura/deploy-date"]);
            deployTime = deployTimeDateObject.toLocaleString();
          }
          catch (e) {
          }
        }

        return (
          <>
            {deployTime}
          </>
        );
      }
    };
  },
});
